import { useEffect, useState } from 'react';
import { IThread, IUser } from '@/types.ts';
import { sdk } from '@/api.ts';
import { Card } from '@/components/ui/card.tsx';
import { BiChat, BiDownload, BiTrash } from 'react-icons/bi';
import { Button } from '@/components/ui/button.tsx';
import { Separator } from '@/components/ui/separator.tsx';
import { useParams } from 'react-router-dom';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import ReactMarkdown from 'react-markdown';
import { defaultSchema } from 'hast-util-sanitize';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { Label } from '@/components/ui/label.tsx';

const initialState: IThread = {
  conversation_statistics: {
    conversation_last_updated: '',
    conversation_created_at: '',
    conversation_length: 0,
  },
  page: 1,
  per_page: 10,
  total_pages: 1,
  total: 0,
  thread_history: [],
};

const sanitizeSchema = {
  ...defaultSchema,
  attributes: {
    ...(defaultSchema.attributes ?? {}),
    a: [...(defaultSchema.attributes?.a ?? []), 'target', 'rel'],
  },
};


const ChatHistory = () => {
  const { user_id } = useParams();

  const [user, setUser] = useState<IUser>();
  const [chatHistory, setChatHistory] = useState<IThread>(initialState);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    try {
      setUser(await sdk.getUser(user_id!))
      await fetchThreads();
    }
    catch (error) {
      console.error('error: ', error);
    }
  }

  const { thread_history, conversation_statistics } = chatHistory;
  const {
    conversation_last_updated,
    conversation_length,
  } = conversation_statistics;

  const fetchThreads = async (params?: Record<string, string>) => {
    const result = await sdk.getChatThreads(user_id!, params);
    setChatHistory(result);
  };

  const handleFilterChange = (value: 'all' | 'deleted' | 'active') => {
    // send no param for all
    if (value === 'all') {
      fetchThreads();
      return;
    }

    // if deleted or active, send appropriate param
    fetchThreads({ active: value === 'active' ? 'true' : 'false' });
  }

  const handleClickDownload = async () => {
    await sdk.downloadChatThread(user_id!);
  };
  const handleClickDelete = async () => {
    await sdk.forgetChatThread(user_id!);
    await fetchThreads();
  };

  return (
      <Card>
        <div className="flex justify-between">
          <div className="flex items-center">
            <BiChat size={24} />
            <div className="pl-2">
              <h3 className="text-sm font-bold">Chat History</h3>
              <p className="text-sm font-semibold text-secondary">{user?.email}</p>
            </div>
          </div>
          <div className="flex gap-x-4">
            <Button variant="outline" onClick={handleClickDownload}>
              <BiDownload className="mr-1" />
              Download
            </Button>
            <Button variant="outline" onClick={handleClickDelete}>
              <BiTrash className="mr-1" />
              Delete
            </Button>
          </div>
        </div>
        <Separator className="my-4" />
        <div className="flex items-center gap-x-4 justify-between text-sm">
          <div className="flex-1">
            <Select defaultValue="all" onValueChange={handleFilterChange}>
              <Label>Filter</Label>
              <SelectTrigger className="w-[180px]">
                <SelectValue placeholder="Select a filter" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="all">All</SelectItem>
                  <SelectItem value="deleted">Deleted</SelectItem>
                  <SelectItem value="active">Active</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
          <div className="flex-1">
            <p className="font-bold">Last Chat</p>
            <p test-id="chat-last-updated">
              {conversation_last_updated || 'N/A'}
            </p>
          </div>
          <div className="flex-1">
            <p className="font-bold">Total Messages</p>
            <p test-id="chat-conversation-length">{conversation_length}</p>
          </div>
        </div>
        <Separator className="my-4" />
        <div
          test-id="chat-history-feed"
          className="max-h-[500px] overflow-y-auto"
        >
          {thread_history.map((message, index) => {
            const isUser = message.user_generated_message;
            const isDeleted = !message.status_active;

            return (
              <div key={index} className={`mb-4  ${isDeleted ? 'border rounded border-error p-2' : 'border-b-2 border-b-accent'} `}>
                <div className="flex justify-between">
                  <div className="flex font-bold">
                    <span className={`${isUser ? '' : 'uppercase'}`}>{isUser ? (user?.first_name || 'User') : 'Peopled'}</span>
                    <span className="ml-1 italic text-error font-medium">{isDeleted ? '(Deleted)' : ''}</span>
                  </div>
                  <div>
                    <p className="text-xs">{message.created_at}</p>
                  </div>
                </div>
                <ReactMarkdown
                  className="markdown"
                  rehypePlugins={[rehypeRaw, [rehypeSanitize, sanitizeSchema]]} // Use rehypeRaw and custom schema
                >
                  {message.message_content}
                </ReactMarkdown>
              </div>
            );
          })}
        </div>
      </Card>
  )
}

export default ChatHistory;