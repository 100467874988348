import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button.tsx';
import { Textarea } from '@/components/ui/textarea.tsx';
import { sdk } from '@/api.ts';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormTextField } from '@/components/text-field.tsx';

const Prompt = ({ organizationId }: { organizationId: string }) => {
  const [currentPrompt, setCurrentPrompt] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const params = useParams();

  if (!organizationId) {
    organizationId = params.id!
  }
  useEffect(() => {
    fetchPrompt();
  }, []);

  useEffect(() => {
    if (success) {
      window.setTimeout(() => {
        setSuccess(false);
      }, 3000)
    }
  }, [success]);

  const FormSchema = z.object({
    systemPrompt: z
      .string()
      .min(1, {
        message: "Prompt must not be empty.",
      }),
    llmProvider: z.string(),
    llmModel: z.string(),
  })

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      systemPrompt: currentPrompt,
      llmProvider: '',
      llmModel: '',
    }
  })

  const fetchPrompt = async () => {
    try {
      const result = await sdk.getPrompt(organizationId);
      form.setValue('systemPrompt', result.system_prompt);
      form.setValue('llmProvider', result.llm_provider);
      form.setValue('llmModel', result.llm_model);
      setCurrentPrompt(result.system_prompt);
    } catch (error) {
      // @TODO: handle error
    }
  }

  const onSubmit = async (data: z.infer<typeof FormSchema>) =>{
    setLoading(true);
    try {
      await sdk.updatePrompt(organizationId, data);
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      //@TODO: handle error
      console.error('error: ', error);
      setLoading(false);
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="w-full space-y-6">
        <div className="flex gap-x-4">
          <FormField
            control={form.control}
            name="llmProvider"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormTextField
                    label="LLM Provider"
                    error={form.formState.errors.llmProvider?.message}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="llmModel"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <FormTextField
                    label="LLM Model"
                    error={form.formState.errors.llmModel?.message}
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={form.control}
          name="systemPrompt"
          render={({ field }) => (
            <FormItem>
              <FormLabel>System Prompt</FormLabel>
              <FormControl>
                <Textarea
                  className="min-h-[400px]"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex gap-x-4 items-center">
          <Button disabled={loading} loading={loading} type="submit">Submit</Button>
          {success ? <p className="text-success">Prompt updated successfully</p>: null}
        </div>
      </form>
    </Form>
  )
}

export default Prompt;